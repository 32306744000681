import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BellModalService } from './modal.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BellAlertComponent } from './components/alert/alert.component';
import { BellConfirmComponent } from './components/confirm/confirm.component';
import { BellErrorComponent } from './components/error/error.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    BellAlertComponent,
    BellConfirmComponent,
    BellErrorComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule
  ],
  providers: [
    NgbModal,
    NgbActiveModal,
    BellModalService
  ]
})
export class BellModalModule { }
